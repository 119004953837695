const Text = {
    H3: ({ label, ...rest }) => {
        const view = <div className="ui-text _h3">
            {label}
        </div>

        return view;
    },
    H2: ({ label, ...rest }) => {
        const view = <div className="ui-text _h2">
            {label}
        </div>

        return view;
    },
    H1: ({ label, ...rest }) => {
        const view = <div className="ui-text _h1">
            {label}
        </div>

        return view;
    }
}


export default Text;