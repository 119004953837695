import Text from '../components/Text';
import './Services.scss';

const Services = {
    Index: () => {
        const list = [
            {
                icon: <i class="fas fa-bacterium"></i>,
                name: 'Pest Control',
                description: 'Hand sprayers, VLV machine, fogging machine, High pressure pump for est control services.'
            }, {
                icon: <i class="fas fa-bath"></i>,
                name: 'Maintenance',
                description: 'Scrubbing machine for cleaning services.'
            },
            {
                icon: <i class="fas fa-tools"></i>,
                name: 'Equipments',
                description: 'Showel, JCB, Buldozer for construction site'
            },
            {
                icon: <i class="fas fa-file-alt"></i>,
                name: 'Documentation Procedure',
                description: 'We provide monthly report on site inspection, construction, cleaning inspection and pest control services.'
            },
            {
                icon: <i class="fas fa-users"></i>,
                name: 'Technician',
                description: 'We have well trained technician for pest control services & cleaning service technician from India & Nepal. We are using muncipality approved pesticide chemical.'
            }];

        const view = <div className="page-services-wrapper">
            <div className="page-services-banner">
                <div className="text"> <Text.H3 label="What We Do" /></div>
            </div>
            <div className="page-services _container">
                <div className="page-services-header">
                    <Text.H3 label="Services we provide" />
                </div>
                <div className="page-services-list columns col-gapless">
                    {
                        list.map(item => <div className="page-services-list-item col-4 col-sm-12">
                            <div className="page-services-list-item-wrapper">
                                <div className="page-services-list-item_img ">
                                    {item.icon}
                                </div>
                                <div className="page-services-list-item-details">
                                    <div className="page-services-list-item-details_name">
                                        <Text.H2 label={item.name} />
                                    </div>
                                    <div className="page-services-list-item-details_description">
                                        {item.description}
                                    </div>
                                </div>
                            </div>
                        </div>)
                    }
                </div>
            </div>
        </div>;

        return view;
    }
}

export default Services;